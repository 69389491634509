









import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class Glitch extends Vue {

  @Prop({default: 3}) stacks!: number;
  @Prop({default: '0'}) delay!: string;

  get stackList() {
    return (new Array(3)).map((_, i) => i);
  }

}

